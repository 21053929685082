@import "../../styles/shared/_variables.scss";

.threeColWrapper {
  padding-bottom: 40px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 40px;

  @include media-breakpoint-down(sm){
    padding-left: 15px;
    padding-right: 15px;
  }

  &.showBg { 
    padding-bottom: 60px;
  }
  .maskedBg {
    position: absolute;
    z-index: 0;
    width: 100%;
    // height: 100%;
    min-height: 360px;
    max-width: 2064px;
    // bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    background-image: linear-gradient(0deg, #EAF5FE, transparent);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @include media-breakpoint-down(sm){
      min-height: 70%;
    }

    @media (min-width: 2064px) {
        max-width: none;
    }

    .mask {
        width: 100%;
        height: 100%;
        background-image: url(../../assets/images/da-swoosh.svg);
        background-position: bottom center;
        background-repeat: no-repeat;
        z-index: 6;
        position: absolute;
        bottom: 0;
        left: 0;

        background-size: 522px auto;

        @media (min-width: 522px) {
        background-size: 800px auto;
        }

        @include media-breakpoint-up(md) {
        background-size: auto;
        }

        @media (min-width: 2064px) {
        background-size: cover;
        }
    }
    img {
        position: relative;
        z-index: 1;

        &.image {
        opacity: 0;
        transition: opacity 0.85s cubic-bezier(0.72, 0, 0.28, 1);
        }
        &.image-loaded {
        opacity: 1;
        }

        @include media-breakpoint-down(md) {
        position: absolute;
        left: 50vw;
        }

        @include media-breakpoint-down(sm) {
        left: 40vw;
        }
    }

    &.expertcoaching {
        bottom: -400px;
    }

    &.guidedonboarding {
        bottom: 140px;
    }
  }

  .threeCol-col {
    max-width: 360px;
    margin-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .threeCol-col:nth-child(2) {
    margin-left: 40px;
    margin-right: 40px;

    @include media-breakpoint-down(sm) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .threeCol-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
    }
  }

  .description {
    margin-bottom: 10px;
    flex: 1;
  }
  .groupLinkSet{
    display: flex;
  }
  .groupLinkSet a{
    font-size: 20px;
    line-height: 23px;
  }

    h2.groupHeader {
        text-align: center;
        font-size: 38px;
      }

    h3.groupHeader {
        flex: 1;
        margin-bottom: 0;
        line-height: 32px;
    }

    h3.groupHeader {
        @include media-breakpoint-down(sm) {
          padding: 0 0 20px 0;
        }
      }

    h4.groupSubHeader {
        @include media-breakpoint-down(sm) {
            margin: 1.25rem 0;
        }
    }

    h2.groupHeader {
      @include media-breakpoint-down(sm) {
        font-size: 28px;
        margin-bottom: 0;
      }
    }
}