@import "../../styles/shared/_variables.scss";

.productGrid {

    .productGridWrapper {
        position: relative;
        z-index: 2;
        padding-top: 5px;
        padding-bottom: 80px;
        margin: 0 auto;

        &.guidedonboarding {
            padding-top: 60px;
        }

        &.expertcoaching{

            @media (min-width: 2064px) {
                padding-bottom: 180px;
            }
        }

    }

    .productRow {
        &.expertcoaching {
            margin: 0 auto;
            position: relative;
            z-index: 2;
        }

        &.guidedonboarding {
            justify-content: center;
            margin: 0 auto;

            .col{
                flex: 0 0 18%;
                width: 200px;

                .productTileWrapper{
                    justify-content: center;
                }

                @include media-breakpoint-down(sm) {
                    width: 100%;
                    flex:auto;
                    }
            }
        }
        
    }

    h2.groupHeader {
        text-align: center;
        font-size: 38px;
        margin-bottom: 2rem;

        @include media-breakpoint-down(sm){
            font-size: 28px;
            line-height: 32px;
          }
      }
    

    .maskedBg {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        top: 50px;
        max-height: 450px;

        @media (min-width: 2064px) {
            max-width: none;
            max-height: 636px;
            top: 0;
        }

        .mask {
            width: 100%;
            height: auto;
            background-image: url(../../assets/images/da-swoosh.svg);
            background-position: bottom center;
            background-repeat: no-repeat;
            z-index: 6;
            position: absolute;
            bottom: 0;
            left: 0;

            background-size: 522px auto;

            &.guidedonboarding{
                height: 100%;
            }

            @media (min-width: 522px) {
            background-size: 800px auto;
            }

            @include media-breakpoint-up(md) {
            background-size: auto;
            }

            @media (min-width: 2064px) {
            background-size: cover;
            }
        }

        img {
            position: relative;
            z-index: 1;
    
            &.image {
            opacity: 0;
            transition: opacity 0.85s cubic-bezier(0.72, 0, 0.28, 1);
            }
            &.image-loaded {
            opacity: 1;
            width: 100%;
            height: auto;

            @include media-breakpoint-down(sm) {
                max-width: 100%;
            }
            }
    
            @include media-breakpoint-down(md) {
            position: absolute;
            left: 50vw;
            }
    
            @include media-breakpoint-down(sm) {
            left: 0;
            }
        }
    }
    .guidedonboardingmask {
        width: 100%;
        background-image: linear-gradient(0deg, #EAF5FE, transparent);
        min-height: 300px;
        top: unset;

        @include media-breakpoint-down(sm) {
            min-height: 70%;
        }
    }

    .expertcoachingmask {
        @include media-breakpoint-down(sm) {
            top: 0;
            bottom: 0;
        }
    }
    
}
