@import "../../styles/shared/_variables.scss";

.trendingTopics {
    padding: 40px 20px 108px 20px;

    .maskedBg {
        position: absolute;
        z-index: 0;
        width: 100%;
        // height: 100%;
        min-height: 580px;
        max-width: 2064px;
        // bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        background-image: linear-gradient(0deg, #EAF5FE, transparent);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    
        @media (min-width: 2064px) {
            max-width: none;
        }
    
        .mask {
            width: 100%;
            height: 100%;
            background-image: url(../../assets/images/da-swoosh.svg);
            background-position: bottom center;
            background-repeat: no-repeat;
            z-index: 6;
            position: absolute;
            bottom: 0;
            left: 0;
    
            background-size: 522px auto;
    
            @media (min-width: 522px) {
            background-size: 800px auto;
            }
    
            @include media-breakpoint-up(md) {
            background-size: auto;
            }
    
            @media (min-width: 2064px) {
            background-size: cover;
            }
        }
        img {
            position: relative;
            z-index: 1;
    
            &.image {
            opacity: 0;
            transition: opacity 0.85s cubic-bezier(0.72, 0, 0.28, 1);
            }
            &.image-loaded {
            opacity: 1;
            }
    
            @include media-breakpoint-down(md) {
            position: absolute;
            left: 50vw;
            }
    
            @include media-breakpoint-down(sm) {
            left: 40vw;
            }
        }
    
        &.expertcoaching {
            bottom: -400px;
        }
    
        &.guidedonboarding {
            bottom: 140px;
        }
    }
}